.affiliation{
   height: 500px;
   margin-bottom: 80px;
   background: #f0f0f0 ;
}

.AffName{
    margin-top: 80px;
    padding-top: 50px;
    margin-bottom: 80px;
    text-align: center;
    color: #101010;
    font-weight: 600;
}

.Image{
    height: 150px;
    width: 200px;
    border: 2px solid black;
    display: block;
    margin: 2px 1em 0 auto;
}

.slick-arrow{
    color: black;
}

.slick-prev{
    z-index: 100;
    left: 20px !important;
}

.slick-next{
    z-index: 100;
    right: 20px !important;
}

