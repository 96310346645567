@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300);
@import url(https://fonts.googleapis.com/css?family=Roboto:300);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

#video{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
body{

    background-color: #fff;
}

.container-fluid{
    background-color: #fff;
}

.card{
    width: 20rem;
    height: 30rem;
    margin-bottom: 2rem;
    align-items: center;
}

@media only screen and (max-width: 960px){
    .card {
        width: 15rem;
    }
}
@media only screen and (max-width: 460px) {
    .card{
        width: 20rem;
    }
}
@media only screen and (max-width: 360px) {
    .card{
        width: 15rem;
    }
}

.heading{
    color: black;
    font-size: 48px;
    font-weight: 600;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    margin-top: -60px;
}

.card:hover{
    box-shadow:  5px 10px 20px 1px rgba(0,0,0,0.6)!important;
    /* box-shadow: 10px 10px blue; */
}

.card-body{
    padding: 2rem 0!important;

}

.card-text{
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}

 .container-fluid{
    padding-top: 6rem;
    padding-bottom: 6rem;
    width: 100%;
 } 

.overflow{
     overflow: hidden;
 }

.card-img-top{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.card-img-top:hover{
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.affiliation{
   height: 500px;
   margin-bottom: 80px;
   background: #f0f0f0 ;
}

.AffName{
    margin-top: 80px;
    padding-top: 50px;
    margin-bottom: 80px;
    text-align: center;
    color: #101010;
    font-weight: 600;
}

.Image{
    height: 150px;
    width: 200px;
    border: 2px solid black;
    display: block;
    margin: 2px 1em 0 auto;
}

.slick-arrow{
    color: black;
}

.slick-prev{
    z-index: 100;
    left: 20px !important;
}

.slick-next{
    z-index: 100;
    right: 20px !important;
}


/* body {
  background: #fff;
  display: flex;
  width: 100%;
  max-width: 1100px;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
} */

.form-container {
  width: 400px;
  background-color: white;
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 10px;
}

.register-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

.success-message {
  font-family: "Roboto", sans-serif;
  background-color: #3f89f8;
  padding: 15px;
  color: white;
}

.form-field {
  margin: 10px 0 10px 0;
  padding: 15px;
  font-size: 16px;
  border: 0;
  font-family: "Roboto", sans-serif;
}

span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: red;
  margin-bottom: 15px;
}

input {
  background: #f2f2f2;
}

textarea{
  background: #f2f2f2;
}

.error {
  border-style: solid;
  border: 2px solid #ffa4a4;
}

button {
  background: #4caf50;
  color: white;
  cursor: pointer;

}

button:disabled {
  cursor: default;
}

/* body {
  background: #fff;
  display: flex;
  width: 100%;
  max-width: 1100px;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
} */
@media only screen and (max-width: 450px){
.form-container {
  width: 270px;
  background-color: white;
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 10px;
} 
}
.register-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

.success-message {
  font-family: "Roboto", sans-serif;
  background-color: #3f89f8;
  padding: 15px;
  color: white;
}

.form-field {
  margin: 10px 0 10px 0;
  padding: 15px;
  font-size: 16px;
  border: 0;
  font-family: "Roboto", sans-serif;
}

span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: red;
  margin-bottom: 15px;
}

input {
  background: #f2f2f2;
}

.error {
  border-style: solid;
  border: 2px solid #ffa4a4;
}

button {
  background: #4caf50;
  color: white;
  cursor: pointer;

}

button:disabled {
  cursor: default;
}

body{

    background-color: #fff;
}

.container-fluid{
    background-color: #fff;
    display: grid;
}

.card{
    width: 20rem;
    height: 30rem;
    margin-bottom: 2rem;
    align-items: center;
}

@media only screen and (max-width: 960px){
    .card {
        width: 15rem;
    }
}
@media only screen and (max-width: 460px) {
    .card{
        width: 20rem;
    }
}
@media only screen and (max-width: 360px) {
    .card{
        width: 15rem;
    }
}

.heading{
    color: black;
    font-size: 48px;
    font-weight: 600;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    margin-top: -60px;
}

.card:hover{
    box-shadow:  5px 10px 20px 1px rgba(0,0,0,0.6)!important;
    /* box-shadow: 10px 10px blue; */
}

.card-body{
    padding: 2rem 0!important;

}

.card-text{
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}

 .container-fluid{
    padding-top: 6rem;
    padding-bottom: 6rem;
    width: 100%;
 } 

.overflow{
     overflow: hidden;
 }

.card-img-top{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.card-img-top:hover{
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
